import { PermissionCheckMixin, VIEW_PERMISSION, PROMOTIONAL_PLANNING_PROMO_LIST, PROMOTIONAL_PLANNING_SCENARIO_BUILDER, PROMOTIONAL_PLANNING_PROMO_UPLOAD_EXPORT, PROMOTIONAL_PLANNING_PROMO_PRODUCT, PROMOTIONAL_PLANNING_PROMO_CUSTOMER, PROMOTIONAL_PLANNING_PROMO_PANDL, PROMOTIONAL_PLANNING_PROMO_FIXED_TERMS, PROMOTIONAL_PLANNING_PROMO_UNIT_PERCENTAGE, PROMOTIONAL_PLANNING_PROMO_LOCATION_LIST, PROMOTIONAL_PLANNING_PROMO_EVENT_LIST, AuthTypes } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "numeral";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "body" }, [_c("portal", { attrs: { "to": "sider-items" } }, [_c("div", { staticClass: "kh-application p-4" }, [_c("p", { staticClass: "m-0" }, [_vm._v("Promotion Planning")]), _c("h5", { staticClass: "mb-0" }, [_vm._v("Promotional Planning ANZ")])]), _c("a-menu", { attrs: { "default-open-keys": _vm.openKeys, "mode": "inline", "theme": "light", "inline-collapsed": _vm.collapsed, "selected-keys": _vm.selectedKeys } }, [_vm._l(_vm.dataMenu, function(item) {
    return [item.key == "home" || !_vm.hasChildMenu(item) && _vm.$can(_vm.permissions.view, item.page) ? [!item.hidden ? _c("a-menu-item", { key: item.key, on: { "click": function($event) {
      return _vm.onItemClick($event);
    } } }, [_c("router-link", { attrs: { "to": item.path } }, [_c("a-icon", { attrs: { "type": item.icon } }), _c("span", [_vm._v(_vm._s(item.title))])], 1)], 1) : _vm._e()] : _vm._e(), _vm.hasChildMenu(item) ? [!item.hidden ? _c("a-sub-menu", { key: item.key }, [_c("span", { attrs: { "slot": "title" }, slot: "title" }, [_c("a-icon", { attrs: { "type": item.icon } }), _c("span", [_vm._v(_vm._s(item.title))])], 1), _vm._l(item.child, function(itemChild) {
      return [!itemChild.hidden && _vm.$can(_vm.permissions.view, itemChild.page) ? _c("a-menu-item", { key: itemChild.key, on: { "click": function($event) {
        return _vm.onItemClick($event);
      } } }, [_c("router-link", { attrs: { "to": itemChild.path } }, [_vm._v(_vm._s(itemChild.title))])], 1) : _vm._e()];
    })], 2) : _vm._e()] : _vm._e()];
  })], 2)], 1), _vm.showChildRoute ? _c("router-view") : _vm._e()], 1);
};
var staticRenderFns = [];
var PromotionalPlanning_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script = {
  name: "PromotionalPlanning",
  metaInfo: {
    title: "Promotional Planning"
  },
  mixins: [PermissionCheckMixin],
  data() {
    return {
      collapsed: false,
      showChildRoute: false,
      permissions: {
        view: VIEW_PERMISSION
      },
      dataMenu: [
        {
          title: "Back to Main Menu",
          path: "/",
          key: "home",
          icon: "appstore",
          child: []
        },
        {
          title: "PromoDB Home Page",
          path: "/promotion-planning/landing-page",
          key: "landing-page",
          icon: "container",
          page: PROMOTIONAL_PLANNING_PROMO_LIST,
          child: []
        },
        {
          title: "Scenario Builder",
          path: "/promotion-planning/scenario-builder",
          key: "scenario-builder",
          page: PROMOTIONAL_PLANNING_SCENARIO_BUILDER,
          icon: "table",
          child: []
        },
        {
          title: "Retail Price",
          path: "/promotion-planning/retail-price",
          icon: "container",
          page: PROMOTIONAL_PLANNING_PROMO_LIST,
          child: []
        },
        {
          title: "Administration",
          path: "",
          key: "administration",
          icon: "solution",
          page: PROMOTIONAL_PLANNING_PROMO_UPLOAD_EXPORT,
          child: [
            {
              title: "Upload/Export",
              path: "/promotion-planning/upload-export",
              key: "upload-export",
              page: PROMOTIONAL_PLANNING_PROMO_UPLOAD_EXPORT
            }
          ]
        },
        {
          title: "Master Files",
          path: "",
          key: "masterfiles",
          icon: "tool",
          child: [
            {
              title: "Products",
              path: "/promotion-planning/products",
              key: "products",
              page: PROMOTIONAL_PLANNING_PROMO_PRODUCT
            },
            {
              title: "Customers",
              path: "/promotion-planning/customers",
              key: "customers",
              page: PROMOTIONAL_PLANNING_PROMO_CUSTOMER
            },
            {
              title: "P&L Lines",
              path: "/promotion-planning/pl-lines",
              key: "pl-lines",
              page: PROMOTIONAL_PLANNING_PROMO_PANDL
            },
            {
              title: "Fixed Terms",
              path: "/promotion-planning/fixed-terms",
              key: "fixed-terms",
              page: PROMOTIONAL_PLANNING_PROMO_FIXED_TERMS
            },
            {
              title: "Unit Percentage",
              path: "/promotion-planning/unit-percentage",
              page: PROMOTIONAL_PLANNING_PROMO_UNIT_PERCENTAGE,
              key: "unit-percentage"
            },
            {
              title: "Activity List",
              path: "/promotion-planning/activity-list",
              key: "activity-list",
              page: PROMOTIONAL_PLANNING_PROMO_LOCATION_LIST
            },
            {
              title: "Event List",
              path: "/promotion-planning/event-list",
              key: "event-list",
              page: PROMOTIONAL_PLANNING_PROMO_EVENT_LIST
            }
          ]
        }
      ],
      openKeys: [],
      rootSubmenuKeys: [],
      selectedKeys: []
    };
  },
  computed: {
    currentRouteInfo() {
      if (!this.$route.matched[1])
        return {};
      const { path, meta } = this.$route.matched[1];
      return { path, meta };
    },
    userInfo() {
      const { namespace, AUTH_GET_USER } = AuthTypes;
      return this.$store.getters[`${namespace}/${AUTH_GET_USER}`];
    }
  },
  watch: {
    currentRouteInfo(newVal) {
      this.updateMenu(newVal);
    },
    userInfo(newVal) {
      let ppRole = newVal.roles && newVal.roles.find((e) => e.app == "promotion-planning" && e.isActive);
      if (ppRole) {
        this.$context.setAppContext(ppRole.entityId);
      }
      this.showChildRoute = true;
    }
  },
  created() {
    if (!this._.isEmpty(this.userInfo))
      this.applyContext(this.userInfo);
    this.updateMenu(this.currentRouteInfo);
  },
  methods: {
    applyContext(userInfos) {
      let ppRole = userInfos.roles && userInfos.roles.find((e) => e.app == "promotion-planning" && e.isActive);
      if (ppRole) {
        this.$context.setAppContext(ppRole.entityId);
      }
      this.showChildRoute = true;
    },
    hasChildMenu(menuItem) {
      if (!menuItem.child.length)
        return false;
      return menuItem.child.some((child) => this.$can(this.permissions.view, child.page));
    },
    updateMenu: function({ path, meta }) {
      this.openKeys = typeof meta == "string" ? [meta.split(";")[0]] : null;
      let title = path.substring(path.lastIndexOf("/") + 1);
      this.selectedKeys = [title];
    },
    onItemClick: function(e) {
      if (!e.key)
        return;
      let { path } = this.currentRouteInfo;
      let title = path.substring(path.lastIndexOf("/") + 1);
      if (title === e.key) {
        this.selectedKeys = [e.key];
      }
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, "5cca61be", null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var PromotionalPlanning = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { PromotionalPlanning as default };
